import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, map, Observable } from 'rxjs';
import { ApiHandlerService } from 'src/app/api/api-handler.service';
import { ApiUrlService } from 'src/app/api/api-url.service';
import { ResponseObj } from '../models/organization.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  public chevronClicked = new BehaviorSubject('');
  public selectedView = 'Chart';
  constructor(private apiService: ApiHandlerService, 
    private apiUrlService: ApiUrlService) { }

  public fetchOrgPanelChevronDataLineManager(payload: any): Observable<ResponseObj>{
    const url = this.apiUrlService.getUrl('ORG_PANEL_CHEVRON_COUNT');
    return this.apiService.post(url, payload).pipe(map((response: any) => {
      return response;
    })
    );
  }

  public fetchOrgPanelTop5UsersData(data): Observable<ResponseObj>{
    const url = this.apiUrlService.getUrl('ORG_PANEL_LIVE_CASES_TOP5_USERS');
    return this.apiService.post(url, data).pipe(map((response: any) => {
      return response;
    })
    );
  }

  public fetchOrgPanelTop5LiveCasesData(data): Observable<ResponseObj>{
    const url = this.apiUrlService.getUrl('ORG_PANEL_LIVE_CASES_TOP5_LIVE_CASES');
    return this.apiService.post(url, data).pipe(map((response: any) => {
      return response;
    })
    );
  }

  public fetchOrgPanelLiveCasesBucketingData(data): Observable<ResponseObj>{
    const url = this.apiUrlService.getUrl('ORG_PANEL_LIVE_CASES_BUKT');
    return this.apiService.post(url, data).pipe(map((response: any) => {
      return response;
    })
    );
  }

  public fetchOrgPanelAvgTATClosureTrendData(data): Observable<ResponseObj>{
    const url = this.apiUrlService.getUrl('ORG_PANEL_AVG_TAT_CLOSURE_TREND');
    return this.apiService.post(url, data).pipe(map((response: any) => {
      return response;
    })
    );
  }

  public fetchOrgPanelGridDataBasedOnStatus(obj): Observable<any>{
    const url = this.apiUrlService.getUrl('ORG_PANEL_GRID_VIEW_BASED_ON_STATUS');
    return this.apiService.post(url, obj).pipe(map((response: any) => {
      return response;
    })
    );
  }

  public fetchOrgPanelGridDataExportBasedOnStatus(obj): Observable<any>{
    const url = this.apiUrlService.getUrl('ORG_PANEL_GRID_EXPORT_BASED_ON_STATUS');
    return this.apiService.post(url, obj).pipe(map((response: any) => {
      return response;
    })
    );
  }

  
  public fetchTaskPanelAlterationDetailsPopupData(obj): Observable<any>{
    const url = this.apiUrlService.getUrl('TASK_PANEL_POPUP_VIEW_BASED_ON_ALTERATION_DETAILS');
    return this.apiService.post(url, obj).pipe(map((response: any) => {
      return response;
    })
    );
  }

  public fetchRaiseQueryDropdownOption(obj): Observable<any>{
    const url = this.apiUrlService.getUrl('TASK_PANEL_RAISE_QUERY_DROPDOWN_OPTION');
    return this.apiService.post(url, obj).pipe(map((response: any) => {
      return response;
    })
    );
  }

  public fetchUserDropdownOption(obj): Observable<any>{
    let obj1 = {
      userRole: obj['userRole'][0]
    }
    let obj2 = {
      userRole: obj['userRole'][1]
    }

    const url = this.apiUrlService.getUrl('TASK_PANEL_RAISE_QUERY_DROPDOWN_OPTION');
    let value1 = this.apiService.post(url, obj1).pipe(map((response: any) => {
      return response;
    }));
    let value2 = this.apiService.post(url, obj2).pipe(map((response: any) => {
      return response;
    }));
    return forkJoin([value1, value2]);
  }

  public sendRaiseQuerySubmitData(obj): Observable<any>{
    const url = this.apiUrlService.getUrl('TASK_PANEL_RAISE_QUERY_SUBMIT_DATA_SUBMITION');
    return this.apiService.post(url, obj).pipe(map((response: any) => {
      return response;
    })
    );
  }
  
  public fetchClosureCommentOptionsSubmit(): Observable<any>{
    const url = this.apiUrlService.getUrl('TASK_PANEL_CLOSURE_OPTIONS_SUBMIT');
    return this.apiService.get(url).pipe(map((response: any) => {
      return response;
    })
    );
  }

  public sendResponsePopupData(obj): Observable<any>{
    const url = this.apiUrlService.getUrl('TASK_PANEL_RESPONSE_DATA_SUBMITION');
    return this.apiService.post(url, obj).pipe(map((response: any) => {
      return response;
    })
    );
  }

  public fetchResponseBtnFileDownload(obj): Observable<any>{
    const url = this.apiUrlService.getUrl('TASK_PANEL_RESPONSE_DOC_DOWNLOAD');
    return this.apiService.post(url, obj).pipe(map((response: any) => {
      return response;
    })
    );
  }

  public fetchViewByFilter(payload: any): Observable<any>{
    const url = this.apiUrlService.getUrl('GRID_VIEW_BY_FILTER_VALUE');
    return this.apiService.post(url, payload).pipe(map((response: any) => {
      return response;
    })
    );
  }

  // DOWNLOAD

  public getFileDetailsSubmit(obj): Observable<any>{
    const url = this.apiUrlService.getUrl('TASK_PANEL_DOWNLOAD_FILE_IN_SUBMIT');
    return this.apiService.post(url, obj).pipe(map((response: any) => {
      return response;
    })
    );
  }

  public getDownloadFile(obj): Observable<any>{
    const url = this.apiUrlService.getUrl('TASK_PANEL_RESPONSE_DOC_DOWNLOAD_FILES');
    return this.apiService.post(url, obj).pipe(map((response: any) => {
      return response;
    })
    );
  }
  
  public sendAssignPopupData(obj): Observable<any>{
    const url = this.apiUrlService.getUrl('TASK_PANEL_ASSIGN_SUBMIT_DATA');
    return this.apiService.post(url, obj).pipe(map((response: any) => {
      return response;
    })
    );
  }




  // public downloadFile(data: any): Observable<any>{
  //   const url = this.apiUrlService.getUrl('EXCEPTION_FILE_DOWNLOAD');
  //   return this.apiService.post(url, data).pipe(map((response: any) => {
  //     return response;
  //   })
  //   );
  // }

}
