import { HttpRequest } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  public onLoadingChanged = new Subject<any>();
  private requests: HttpRequest<any>[] = [];
  constructor() { }

  onStarted(req: HttpRequest<any>): void {
    this.requests.push(req);
    
    this.onLoadingChanged.next(false);
    this.notify();
  }

   
  /**
   * Removes request from the storage and notifies observers
   */
  onFinished(req: HttpRequest<any>): void {
    const index = this.requests.indexOf(req);
    if (index !== -1) {
      this.requests.splice(index, 1);
    }
    this.notify();
  }
  
  /**
   * Notifies observers about whether there are any requests on fly
   */
  private notify(): void {
    this.onLoadingChanged.next(this.requests.length !== 0);
  }


}
