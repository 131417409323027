import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { urlConfig } from '../api/apiUrlConfig';
import {BehaviorSubject, Observable, Subject } from 'rxjs';

export const ENV = environment;
export const URL_CONFIG = urlConfig;

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private reloadData = new BehaviorSubject({reload: false, pageConfig: {name: ''} });
  constructor() { }


  reloadPageOnClick(pageConfig) {
    this.reloadData.next({reload: true, pageConfig });
}

onMessage(): Observable<any> {
  return this.reloadData.asObservable();
}


}
