import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  public pageDetails = '';
  public headerRefresh = new BehaviorSubject({refresh: false, pageConfig: {name: ''} }); 
  constructor() { }
}
