import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiHandlerService } from 'src/app/api/api-handler.service';
import { ApiUrlService } from 'src/app/api/api-url.service';
import { ResponseObj } from '../models/organization.model';
@Injectable({
  providedIn: 'root'
})
export class RuleService {
  selectedTabValue: string ='Embedded';

  constructor(private apiService: ApiHandlerService, 
    private apiUrlService: ApiUrlService) { }

  public fetchRuleEntityList(): Observable<ResponseObj> {
    const url = this.apiUrlService.getUrl('CR_ED_RULE_ENTITY');
    return this.apiService.post(url).pipe(map((response: ResponseObj) => {
      return response;
    })
    );
  }

  
  public fetchRuleBuilderMetadata(entityName): Observable<any> {
    let obj = {
      Entity: entityName
    };
    const url = this.apiUrlService.getUrl('CR_ED_RULE_BUILDER_ATTRIBUTES');
    return this.apiService.post(url, obj).pipe(map((response: any) => {
      return response;
    })
    );
  }

  public fetchRuleBuilderOperator(): Observable<ResponseObj> {
    const url = this.apiUrlService.getUrl('CR_ED_RULE_BUILDER_OPERATOR');
    return this.apiService.post(url).pipe(map((response: ResponseObj) => {
      return response;
    })
    );
  }

  public createOrDraftRule(ruleData): Observable<ResponseObj> {
    const url = this.apiUrlService.getUrl('CR_ED_SUBMIT_OR_DRAFT');
    return this.apiService.post(url, ruleData).pipe(map((response: any) => {
      return response;
    })
    );
  }
  public getRuleFilterApplyForML(filterObj: any): Observable<Array<any>> {
    const url = this.apiUrlService.getUrl('RULE_DETAILS_ML');
    return this.apiService.post(url, filterObj).pipe(map((response: Array<any>) => {
      return response;
    })
    );
  }

  public getRuleFilterApply(filterObj: any): Observable<Array<any>> {
    const url = this.apiUrlService.getUrl('RULE_CANVAS_RULE_DETAILS');
    return this.apiService.post(url, filterObj).pipe(map((response: Array<any>) => {
      return response;
    })
    );
  }
  public fetchTableColumnConfig(data: any): Observable<any>{
    const url = this.apiUrlService.getUrl('TABLE_COLUMN_CONFIG');
    return this.apiService.post(url, data).pipe(map((response:Array<any>) => {
      return response;
    })
    );
  }
  public getRuleFilterViewML(filterName: string): Observable<Array<any>> {
    const url = this.apiUrlService.getUrl('RULE_FILTER_VIEW_ML');
    let ruleObj = {
      "FilterColumn" : filterName
    };
    return this.apiService.post(url, ruleObj).pipe(map((response: Array<any>) => {
      return response;
    })
    );
  }
  public getRuleFilterView(filterName: string, selectedTab: string): Observable<Array<any>> {
    const url = this.apiUrlService.getUrl('RULE_FILTER_VIEW');
    let ruleObj = {
      "FilterColumn" : filterName,
      "RuleStatus" : selectedTab,
    };
    return this.apiService.post(url, ruleObj).pipe(map((response: Array<any>) => {
      return response;
    })
    );
  }
  public activateDeactivateRule(obj: any): Observable<Array<any>> {
    const url = this.apiUrlService.getUrl('RULE_ACTIVATE_DEACTIVATE');
    return this.apiService.post(url, obj).pipe(map((response: Array<any>) => {
      return response;
    })
    );
  }

  public fetchEditRuleDetails(ruleObj): Observable<Array<any>> {
    const url = this.apiUrlService.getUrl('RULE_CANVAS_EM_EDIT_RULE_DETAILS');
    return this.apiService.post(url,ruleObj).pipe(map((response: Array<any>) => {
      return response;
    })
    );
  }
  public fetchEditRuleBuilderDetails(ruleObj): Observable<Array<any>> {
    const url = this.apiUrlService.getUrl('CR_ED_RULE_BUILDER_DETAILS');
    return this.apiService.post(url,ruleObj).pipe(map((response: Array<any>) => {
      return response;
    })
    );
  }

  public fetchAnomaliesList(obj: any) {
    const url = this.apiUrlService.getUrl('RULE_CANVAS_ANOMALIES_LIST');
    return this.apiService.post(url, obj).pipe(map((response: Array<any>) => {
      return response;
    })
    );
  }

  public updateRuleDetails(ruleData): Observable<Array<any>> {
    const url = this.apiUrlService.getUrl('RULE_DETAILS_EM_SUBMIT_SAVE_DRAFT');
    return this.apiService.post(url, ruleData).pipe(map((response: any) => {
      return response;
    })
    );
  }

  public fetchMLModelQueue(): Observable<Array<any>> {
    const url = this.apiUrlService.getUrl('RULE_ML_MODEL_QUEUE');
    return this.apiService.get(url).pipe(map((response: Array<any>) => {
      return response;
    })
    );
  }
  public runMLModel(filterObj: any): Observable<Array<any>> {
    const url = this.apiUrlService.getUrl('RULE_RUN_ML_MODEL');
    return this.apiService.post(url, filterObj).pipe(map((response: Array<any>) => {
      return response;
    })
    );
  }
  public fetchRuleEntityListForML(): Observable<Array<any>> {
    const url = this.apiUrlService.getUrl('RULE_CANVAS_ML_ENTITY');
    return this.apiService.get(url).pipe(map((response: Array<any>) => {
      return response;
    })
    );
  }
}
