import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { SpinnerService } from 'src/app/interceptor/spinner.service';
import { AuthService } from 'src/app/modules/auth/service/auth.service';
import { MessagingService } from 'src/app/shared/messaging.service';
import { pageNameConfig } from './config/pageNameConfig';
import { HeaderService } from './service/header.service';
import { UtilsService } from 'src/app/shared/utils/utils.service';
import { Subscription } from 'rxjs';
import { RuleService } from 'src/app/modules/rule/service/rule.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild ('userActionPanel', {static: false}) userActionPanel;
  currentRoute: any = '';
  pageConfig: any;
  userInitial = '';
  userlastLoginTime = '';
  preAuthHeader = false;
  specialHeaderPageList = [
    '/',
    '/auth/register',
    '/auth/reset-password',
    '/auth/password-confirmation',
  ];
  private authSubscription: Subscription = new Subscription;

  constructor(
    private router: Router, 
    public headerService: HeaderService,
    private location: Location, 
    public authService: AuthService,
    public spinnerService: SpinnerService, 
    public utilsService: UtilsService, 
    private ruleService: RuleService,
    private messagingService: MessagingService) { }

  ngOnInit(): void {
    this.authSubscription = this.authService.authState.subscribe(data => {
      this.userInitial = this.utilsService.getNameInitials(this.authService.userDetails.userName);
      this.userlastLoginTime = this.authService.userDetails.lastLoginTime;
    })
    this.checkRouteEvents();
  }

  checkRouteEvents() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.headerService.pageDetails = '';
        this.currentRoute = event.url.trim();
        this.pageConfig = pageNameConfig.filter(data => {
          return data.url === this.currentRoute;
        });
        // console.log(this.pageConfig);
        if(this.pageConfig.length > 0){
          this.pageConfig = this.pageConfig[0];
        }

        // checking for preAuthHeader
        if (this.specialHeaderPageList.includes(this.currentRoute)) {
          this.preAuthHeader = true;
        } else {
          this.preAuthHeader = false;
        }
        
        if(this.currentRoute.indexOf('rule')< 0){
          this.resetRuleTab();
        }
      }
    });
  }

  resetRuleTab(){
    this.ruleService.selectedTabValue = 'Embedded';
  }

  getRoleName(role){
    if (role === 'LM') {
      return 'Line Manager';
    } else if (role === 'HM') {
      return 'HUL Maker';
    } else if(role === 'BM') {
      return 'Business Manager';
    } else {
      return 'NA';
    }
  }

  userLogout(){
    this.userActionPanel.hide();
    this.authService.userLogout();
    this.authService.authState.next('');
  }
 
  reloadCurrentPage(){
    let obj = {
      refresh: true, pageConfig: this.pageConfig 
    };
    this.headerService.headerRefresh.next(obj);
  }

  clickBackButton(){
    this.location.back();
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe()
  }

}
