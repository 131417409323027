import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
export const ENV= environment;
@Injectable({
  providedIn: 'root'
})
export class BrowserStorageService {
  encodeValue = false;
  constructor() { 
    if(ENV.debug === true){
      this.encodeValue = false;
    } else {
      this.encodeValue = true;
    }
  }

  setValueInSession(keyName: string, value: string){
    if(this.encodeValue){
      value = window.btoa(value);
    }
    sessionStorage.setItem(keyName,value);
  }

  getValueInSession(keyName: string){
    let value: any = '';
    value = sessionStorage.getItem(keyName);
    if(this.encodeValue && value){
      value = window.atob(value);
    }
    return value;
  }

  removeValueInSession(keyName: string){
    sessionStorage.removeItem(keyName);
  }

  removeAllInSession(){
    sessionStorage.clear();
  }


  setValueInLocal(keyName: string, value: string){
    if(this.encodeValue){
      value = window.btoa(value);
    }
    localStorage.setItem(keyName,value);
  }

  getValueInLocal(keyName: string){
    let value: any = '';
    value = localStorage.getItem(keyName);
    if(this.encodeValue && value){
      value = window.atob(value);
    }
    return value;
  }

  removeValueInLocal(keyName: string){
    localStorage.removeItem(keyName);
  }

  removeAllInLocal(){
    localStorage.clear();
  }
}
