import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { LeftPanelComponent } from './layout/left-panel/left-panel.component';
import { SharedModule } from './shared/shared.module';
import { FooterComponent } from './layout/footer/footer.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VimInterceptor } from './interceptor/vim.interceptor';
import { HttpCancelService } from './interceptor/http-cancel.service';
import { registerLocaleData } from '@angular/common';
import localeIn from '@angular/common/locales/en-IN';
import { SpinnerService } from './interceptor/spinner.service';
import { SpinnerComponent } from './layout/spinner/spinner.component';

registerLocaleData(localeIn);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LeftPanelComponent,
    FooterComponent,
    SpinnerComponent,
  ],
  imports: [
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule
  ],
  providers: [ 
    HttpCancelService,
    SpinnerService,
    { provide: HTTP_INTERCEPTORS, useClass: VimInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "en-IN" },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
