import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../modules/auth/service/auth.service';
import { BrowserStorageService } from '../shared/browser-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  loginStatus = false;
  constructor(private router: Router, private browserStorageService: BrowserStorageService, private authService: AuthService){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let userDetails = JSON.parse(this.browserStorageService.getValueInSession('userDetails'));
      if(userDetails?.uid){
        this.authService.userDetails = userDetails;
        return true;
      } else {
        this.router.navigate(['']);
        return false;
      }
  }
  
}
