export const pageNameConfig = [
    {
        url : '/landing/organization-view',
        dispName : 'Organization ',
        back : false, 
        refresh: true,
        name: 'Organization_Page'
    },
    {
        url : '/landing/task-view',
        dispName : 'My Task ',
        back : false, 
        refresh: true,
        name: 'Organization_Page'
    },
    {
        url : '/landing/caseDetails',
        dispName : 'Organization Case Details',
        back : true, 
        refresh: false,
        name: 'Organization_CaseDetails'
    },
    {
        url : '/admin/job-status',
        dispName : 'Job Status',
        back : true, 
        refresh: false,
        name: 'Job_Status'
    },
    {
        url : '/admin/data-ingestion',
        dispName : 'Data Ingestion',
        back : true, 
        refresh: false,
        name: 'Data_Ingestion'
    },
    {
        url : '/admin/users-view',
        dispName : 'Users View',
        back : true, 
        refresh: false,
        name: 'Users_View'
    },
    {
        url : '/admin/create-user',
        dispName : 'Create new user',
        back : true, 
        refresh: false,
        name: 'Create_User'
    },
    {
        url : '/admin/edit-user',
        dispName : 'Edit user',
        back : true, 
        refresh: false,
        name: 'Edit_User'
    },
    {
        url : '/admin/roles-view',
        dispName : 'Roles View',
        back : true, 
        refresh: true,
        name: 'Roles_View'
    },
    {
        url : '/admin/edit-role',
        dispName : 'Detailed Roles View',
        back : true, 
        refresh: true,
        name: 'Edit_Role'
    },
    {
        url : '/rule/list-view',
        dispName : 'Rule List',
        back : true, 
        refresh: true,
        name: 'CreateRule'
    },
    {
        url : '/rule/create-rule',
        dispName : 'Create Rule',
        back : true, 
        refresh: false,
        name: 'CreateRule'
    },
    {
        url : '/rule/ml-anomalies',
        dispName : 'ML Anomalies',
        back : true, 
        refresh: false,
        name: 'Ml_Anomalies'
    },
    {
        url : '/rule/edit-rule',
        dispName : 'Edit Rule',
        back : true, 
        refresh: false,
        name: 'EditRule'
    }
    
];