import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiHandlerService {
  public api_url: string;
  constructor(
    private http: HttpClient,
    protected configService: ConfigService) {
      this.api_url = '';
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  get(path: string, body: Object = {}, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}, options?: any): Observable<any> {
    return this.http.put(
      `${path}`,
      JSON.stringify(body),
      options
    ).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}, options?: any): Observable<any> {
    return this.http.post(
      path,
      body
    ).pipe(catchError(this.formatErrors));
  }
}
