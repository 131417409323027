import { ChangeDetectorRef, Component } from '@angular/core';
import { NavigationEnd, Event, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { SpinnerService } from './interceptor/spinner.service';
import { UtilsService } from './shared/utils/utils.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})
export class AppComponent {
  hideLeftPanel = true;
  loading = false;
  noSidePanelPageList = [
    '/',
    '/auth/register',
    '/auth/reset-password',
    '/auth/password-confirmation',
  ];
  constructor(
    private router: Router,
    private utils: UtilsService, 
    public spinnerService: SpinnerService,
    private cdr : ChangeDetectorRef,
  ) {
    this.checkRouteEvents();
    this.checkLoading();
  }

  ngAfterViewChecked(){
    this.cdr.detectChanges();
  }
  checkLoading() {
    this.spinnerService.onLoadingChanged.subscribe((isLoading) => {
      this.loading = isLoading
    }
    );
  }

  checkRouteEvents() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (this.noSidePanelPageList.includes(event.url.trim())) {
          this.hideLeftPanel = true;
        } else {
          this.hideLeftPanel = false;
        }
      }
    });
  }
}
