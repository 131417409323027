
export enum UserAccess {
    CHECK_JOB_STATUS = 'checkJobStatus',
    CLOSE_CASES = 'closeCases',
    CREATE_DELETE_USERS = 'createDeleteUsers',
    CREATE_RULE = 'createRule',
    DASHBOARD_1 = 'dashboard1',
    DASHBOARD_2 = 'dashboard2',
    DASHBOARD_3 = 'dashboard3',
    DASHBOARD_4 = 'dashboard4',
    EMBED_RULE = 'embedRule',
    INACTIVE_RULE = 'inactivateRule',
    MODIFY_RULE = 'modifyRule',
    MODIFY_USER_ROLE_MAPPING = 'modifyUserRoleMapping',
    RAISE_QUERY = 'raiseQueries',
    REASSIGN_CASES = 'reassignCases',
    RESPOND_QUERIES = 'respondQueries',
    SCHEDULE_ML_RUN = 'scheduleMLRun',
    VIEW_ROLES = 'viewRoles',
}