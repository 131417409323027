import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './guards/admin.guard';
import { LoginGuard } from './guards/login.guard';
import { RulesGuard } from './guards/rules.guard';
import { PagenotfoundComponent } from './shared/components/pagenotfound/pagenotfound.component';

const routes: Routes = [
  // { path: '', redirectTo: '/landing', pathMatch: 'full'},
  { path: '', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'landing', canActivate: [LoginGuard], loadChildren: () => import('./modules/organization/organization.module').then(m => m.OrganizationModule) },
  { path: 'rule', canActivate: [LoginGuard, RulesGuard], loadChildren: () => import('./modules/rule/rule.module').then(m => m.RuleModule) },
  { path: 'admin', canActivate: [LoginGuard, AdminGuard], loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule) },
  { path: 'dashboard/:reportId/:ctid', canActivate: [LoginGuard], loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule) },
  //Wild Card Route for 404 request 
  { path: '**', pathMatch: 'full',  component: PagenotfoundComponent }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
