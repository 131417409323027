import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PrimengModule } from './primeng/primeng.module';
import { FormsModule } from '@angular/forms';
import { NgxEchartsModule } from 'ngx-echarts';
import { SafePipe } from './pipes/safe.pipe';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';

@NgModule({
  declarations: [
    SafePipe,
    PagenotfoundComponent
  ],
  imports: [
    CommonModule,
    PrimengModule,
    FormsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
  ],
  providers: [
    DatePipe
  ],
  exports: [ 
    CommonModule,
    FormsModule,
    PrimengModule,
    NgxEchartsModule,

    SafePipe,

    PagenotfoundComponent
  ] 
})
export class SharedModule { }
