import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/modules/auth/service/auth.service';
import { ENV } from 'src/app/shared/global.service';
import { Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { ColdObservable } from 'rxjs/internal/testing/ColdObservable';
import { leftPanelConfig } from '../../layout/left-panel/config/leftPanelConfig';
import { filter } from 'rxjs/operators';
import { UserAccess } from 'src/app/modules/auth/models/auth.model';
import { MessageService } from 'primeng/api';
import { UtilsService } from 'src/app/shared/utils/utils.service';

@Component({
  selector: 'app-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.scss']
})
export class LeftPanelComponent implements OnInit {
  environmentName = '';
  viewTypeOnUser = '';
  pageName: any;
  currentRouteUrl: any;
  currentRoute: string = '';
  leftPanelConfig;
  userAccess = UserAccess;
  userRole;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private utilsService: UtilsService,
  ) {
    this.environmentName = ENV.app.name;
    this.viewTypeOnUser = this.authService.getLoggedinUser()?.userRole;
    this.leftPanelConfig = this.utilsService.deepCopy(leftPanelConfig);
    this.checkRouteEvents();
  }

  checkRouteEvents() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.trim() === '') {
          this.leftPanelConfig = this.utilsService.deepCopy(leftPanelConfig);
        }
        this.viewTypeOnUser = this.authService.getLoggedinUser()?.userRole;
        this.userRole = this.authService.userAccess;
        this.checkNavAccessByRole();
        console.log('nav', this.leftPanelConfig, this.authService.getLoggedinUser());
      }
    });
  }

  ngOnInit(): void {
    this.getRouteData();
    this.userRole = this.authService.userAccess;

    // checking for nav item access
    this.checkNavAccessByRole();

    console.log(this.leftPanelConfig, this.authService.getLoggedinUser());
  }

  redirection(route: string): void {
    this.router.navigate([route]);
    // console.log("this.router.navigate([route])", this.router.navigate([route]))
  }

  redirectTo(value: string) {
    this.router.navigate(['landing', value]);
  }

  onClickMainOptionDropdown(index) {
    if(this.leftPanelConfig[index].submenu && this.leftPanelConfig[index].active) {
      this.leftPanelConfig[index].active = false;
      return;
    }
    /** checking if accessable */
    let access = true;
    if(this.leftPanelConfig[index].navAccess.length > 0) {
      access = false;
      for(let accessItem of this.leftPanelConfig[index].navAccess) {
        if(this.userRole[this.userAccess[accessItem]]) {
          access = true;
          break;
        }
      }
    }
    if(!access) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'You don\'t have access to view this item' });
      return;
    }

    this.setActivation(index);
    if (this.leftPanelConfig[index].submenu) {
      this.highlightSelectedSubOption(this.leftPanelConfig, index, false);
      this.router.navigate([this.leftPanelConfig[index].submenuitems[0].url]);
    } else {
      this.router.navigate([this.leftPanelConfig[index].url]);
    }
  }

  onClickSubOptionDropdown(value, dropdownOptionList, clickIndex) {
    /** checking if accessable */
    let access = true;
    if(dropdownOptionList[clickIndex].navAccess?.length > 0) {
      access = false;
      for(let accessItem of dropdownOptionList[clickIndex].navAccess) {
        if(this.userRole[this.userAccess[accessItem]]) {
          access = true;
          break;
        }
      }
    }
    if(!access) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'You don\'t have access to view this item' });
      return;
    }

    this.highlightSelectedSubOption(dropdownOptionList, clickIndex, true);
    dropdownOptionList.forEach(( currentOption, index) => {
    });
    this.router.navigate([value.url]);
  }

  highlightSelectedSubOption(dropdownOptionList, currentIndex, isSubMenu) {
    this.resetHighlightedValue();
    if (isSubMenu) {
      dropdownOptionList.forEach((currentOption, index) => {
        if (currentIndex === index) {
          currentOption.highlight = true;
        } else {
          currentOption.highlight = false;
        }
      });
    } else {
      if (dropdownOptionList[currentIndex].submenu) {
        this.leftPanelConfig.forEach((currentOption, index) => {
          if(currentOption.submenu && currentIndex == index){
            currentOption.submenuitems[0].highlight = true;
          }
        });
      } else {
        this.resetHighlightedValue();
      }
    }
  }

  resetHighlightedValue() {
    this.leftPanelConfig.forEach((currentOption) => {
      if(currentOption.submenu){
        currentOption.submenuitems.forEach((currentSubOption) => {
            currentSubOption.highlight = false;
        });
      }
    });
  }

  getRouteData(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe(event => {
        this.currentRouteUrl = event['url'];
        this.pageName = (this.currentRouteUrl.substring(this.currentRouteUrl.lastIndexOf('/') + 1)).toString();
      });
  }

  setActivation(clickIndex) {
    this.leftPanelConfig.forEach((currentValue, index) => {
      if (clickIndex == index) {
        currentValue.active = !currentValue.active;
      } else {
        if (currentValue.submenu) {
          // currentValue.active = false;
        }
      }
    });
  }

  checkNavAccessByRole() {
    this.leftPanelConfig = this.utilsService.deepCopy(leftPanelConfig);
    this.leftPanelConfig.forEach(mainNav => {
      let access = true;
      if(mainNav.navAccess.length > 0) {
        access = false;
        for(let accessItem of mainNav.navAccess) {
          if(this.userRole[this.userAccess[accessItem]]) {
            access = true;
            break;
          }
        }

        if(access) {
          mainNav.submenuitems.forEach(subNav => {
            let subAccess = true;
            if(subNav.navAccess.length > 0) {
              subAccess = false;
              for(let accessItem of subNav.navAccess) {
                if(this.userRole[this.userAccess[accessItem]]) {
                  subAccess = true;
                  break;
                }
              }
              if(!subAccess) {
                subNav['hidden'] = true;
              }
            }
          });
        } else {
          mainNav['hidden'] = true;
        }
      }
    });

  }

}
