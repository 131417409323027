import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { ApiHandlerService } from 'src/app/api/api-handler.service';
import { ApiUrlService } from 'src/app/api/api-url.service';
import { BrowserStorageService } from 'src/app/shared/browser-storage.service';
import { OrganizationService } from '../../organization/service/organization.service';
import { UserAccess } from '../models/auth.model';
import { ResponseObj } from '../models/organization.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userMapingDummy = [
    { user: 'Jingxia Wu', role: 'HUL Maker' },
    { user: 'Shengyu Xu', role: 'HUL Maker' },
    { user: 'Xiaoxiao Yu', role: 'HUL Maker' },
    { user: 'Aniket Borse', role: 'Line Manager' },
    { user: 'Shaik P Basha - GSK', role: 'Business Manager' },
    { user: 'Jyothi K', role: 'Business Manager' },
    { user: 'Chaitra C', role: 'Business Manager' }
  ];
  //   user1 : "Sukanta Roy",       //    Line Manager 
  //   user2 : "Kaustav Chaudhuri", //    HUL Maker
  //   user3 : "Ashish Inamdar"     //    Business Manager
  // };

  public authState = new BehaviorSubject('');

  userDetails: any = {};
  userAccess: UserAccess | any = {};
  constructor(
    private apiUrlService: ApiUrlService,
    private router: Router,
    private browserStorageService: BrowserStorageService,
    private apiService: ApiHandlerService,
    private orgService: OrganizationService,
  ) { }

  public fetchUserLoginDetails(obj): Observable<ResponseObj> {
    const url = this.apiUrlService.getUrl('HUL_LOGIN_API');
    return this.apiService.post(url, obj).pipe(map((response: any) => {
      return response;
    })
    );
  }

  public userLogout() {
    this.orgService.chevronClicked.next('reset');
    this.orgService.selectedView = 'Chart';
    this.userDetails = {};
    this.userAccess = {};
    this.browserStorageService.removeAllInSession();
    this.router.navigate(['']);

  }

  public getLoggedinUser() {
    let userDetails = JSON.parse(this.browserStorageService.getValueInSession('userDetails'));
    this.userAccess = JSON.parse(this.browserStorageService.getValueInSession('userAccess')) || {};
    if (userDetails?.uid) {
      return this.userDetails = userDetails;
    }
  }

  public checkAdminMenuAccess() {
    let userAccess = UserAccess;
    let access = false;
    let navAccess = ['CHECK_JOB_STATUS', 'CREATE_DELETE_USERS', 'MODIFY_USER_ROLE_MAPPING', 'VIEW_ROLES'];
    let userRole: UserAccess = this.userAccess;
    for(let accessItem of navAccess) {
      if(userRole[userAccess[accessItem]]) {
        access = true;
        break;
      }
    }

    if(access) {
      return true;
    } else {
      this.router.navigate(['/auth/not-authorized']);
      return false;
    }
  }

  public checkAccess(accessArr) {
    let userAccess = UserAccess;
      let access = false;
      let navAccess = accessArr;
      let userRole: UserAccess = this.userAccess;
      for(let accessItem of navAccess) {
        if(userRole[userAccess[accessItem]]) {
          access = true;
          break;
        }
      }

      if(access) {
        return true;
      } else {
        this.router.navigate(['/auth/not-authorized']);
        return false;
      }
  }

  
  public loginUser(payload: any): Observable<any> {
    const url = this.apiUrlService.getUrl('USER_LOGIN');
    return this.apiService.post(url, payload).pipe(map((response: any) => {
      return response;
    })
    );
  }

  public resetPassword(payload: any): Observable<any> {
    const url = this.apiUrlService.getUrl('RESET_PASSWORD');
    return this.apiService.post(url, payload).pipe(map((response: any) => {
      return response;
    })
    );
  }

  public forgotPassword(payload: any): Observable<any> {
    const url = this.apiUrlService.getUrl('Forgot_PASSWORD');
    return this.apiService.post(url, payload).pipe(map((response: any) => {
      return response;
    })
    );
  }


}
