import { Component, OnInit } from '@angular/core';
import { ENV } from 'src/app/shared/global.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  buildTime: any;
  environmentName = '';
  constructor() {

    this.buildTime = ENV.time;
    this.environmentName = ENV.app.name;
    //this.setBuildDateTime();
  }


  setBuildDateTime() {
    let dateValue = new Date(ENV.time);
    let time = dateValue.getHours() +':'+dateValue.getMinutes()+':'+dateValue.getSeconds();
    let date = dateValue.getDate() + '/' + (dateValue.getMonth()+1) + '/' + dateValue.getFullYear();
    this.buildTime = date + '|' + time; 

  }

  ngOnInit(): void {
  }

}
