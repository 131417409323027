export const urlConfig = {
    HUL_LOGIN_API: '/user/login',
    ORG_PANEL_CHEVRON_COUNT : '/dashboard/GetOrganizationalPanelChevron',
    ORG_PANEL_LIVE_CASES_BUKT: '/dashboard/LiveCaseBucketing', 
    ORG_PANEL_LIVE_CASES_TOP5_USERS: '/dashboard/getuserdata',
    ORG_PANEL_LIVE_CASES_TOP5_LIVE_CASES: '/dashboard/GetOrgGraphData',
    ORG_PANEL_AVG_TAT_CLOSURE_TREND: '/dashboard/GetAvgTaTData',
    ORG_PANEL_GRID_VIEW_BASED_ON_STATUS: '/taskQueue/GetGridData',
    ORG_PANEL_GRID_EXPORT_BASED_ON_STATUS: '/taskQueue/GetExportData',
    GRID_VIEW_BY_FILTER_VALUE: '/taskQueue/GetFilterColumnValue',
    TASK_PANEL_POPUP_VIEW_BASED_ON_ALTERATION_DETAILS: '/taskQueue/GetAlterationDetails',
    TASK_PANEL_RAISE_QUERY_DROPDOWN_OPTION: '/Common/GetUserList',
    TASK_PANEL_RAISE_QUERY_SUBMIT_DATA_SUBMITION: '/File/UploadFiles', //RAISE Query and Submit
    TASK_PANEL_CLOSURE_OPTIONS_SUBMIT:'/Common/GetCommentList',

    TASK_PANEL_RESPONSE_DOC_DOWNLOAD_FILES: '/File/DownloadFiles',
    // TASK_PANEL_RESPONSE_DATA_SUBMITION:  'File/UploadFiles',


    TASK_PANEL_DOWNLOAD_FILE_IN_SUBMIT: '/Common/GetFileDetails',
    // '/File/UploadFiles' api/Common/GetCommentList

    TASK_PANEL_ASSIGN_SUBMIT_DATA: '/TaskQueue/GetAssigningAndReassignDetails',

    ADMIN_MODULE_FILE_PREVIEW: '/File/FilePreview',
    FETCH_JOB_STATUS_DETAILS: '/Admin/GetjobStatusDetails',
    FETCH_JOB_STATUS_FILTER_VIEW: '/Admin/GetFilterJobStatus',
    FETCH_FILE_INGESTION_ENTITY_LIST: '/Admin/GetFileIngestionEntityList',
    UPLOAD_FILE_INGESTION_FILES: '/File/FileIngestionUploadFiles',
    FETCH_ROLE_DETAILS: '/Admin/GetRoleDetails',
    DOWNLOAD_FILE_IN_DATA_INGEST: '/File/DownloadFileFromBlob',
    FETCH_ROLE_ACESS_MANAGEMENT: '/Admin/GetRoleAccessManagement',
    SAVE_ROLE_ACESS_MANAGEMENT: '/Admin/SaveRoleDetails',
    FETCH_USERS_VIEW_GRID_DATA: '/user/GetUsersView',
    USER_LOGIN: '/user/userlogin',
    RESET_PASSWORD: '/user/ChangePassword',
    Forgot_PASSWORD: '/user/ForgotPassword',
    USER_VIEW_ACTION_BUTTON: '/admin/UserActions',
    USER_VIEW_FILTER_VIEW: '/admin/GetFilterUserData',
    USER_VIEW_ESCALATION_MANAGER_DETAILS: '/admin/GetEscalatioManagerDetails',
    CR_ED_RULE_ENTITY: '/rulecanvas/GetEntityList',
    CR_ED_RULE_BUILDER_ATTRIBUTES: '/rulecanvas/GetAttributesBuilderDetails',
    CR_ED_RULE_BUILDER_OPERATOR: '/rulecanvas/GetOperatorList',
    CR_ED_SUBMIT_OR_DRAFT: '/rulecanvas/CreateCustomRule',
    RULE_CANVAS_RULE_DETAILS: '/rulecanvas/GetRuleAllTabDetails',
    RULE_CANVAS_EM_EDIT_RULE_DETAILS: '/rulecanvas/GetRuleDetails',
    RULE_ACTIVATE_DEACTIVATE: '/rulecanvas/GetActivateDeactivateAndApprovalInvalidate',
    CR_ED_RULE_BUILDER_DETAILS: '/rulecanvas/GetRuleBuilderDetails',
    RULE_FILTER_VIEW: '/rulecanvas/GetRuleFilterView',
    RULE_DETAILS_ML: '/rulecanvas/GetMlSuggestedDetails',
    RULE_CANVAS_ANOMALIES_LIST: '/rulecanvas/GetMlRuleAnomaliesDetails',
    RULE_FILTER_VIEW_ML: '/rulecanvas/GetMlSuggestedFilter',
    RULE_DETAILS_EM_SUBMIT_SAVE_DRAFT:'/rulecanvas/SaveAsDraft',
    RULE_ML_MODEL_QUEUE: '/rulecanvas/GetMlModelQueue',
    RULE_RUN_ML_MODEL: '/rulecanvas/RunMlModel',
    RULE_CANVAS_ML_ENTITY: '/rulecanvas/GetEntityList'
}