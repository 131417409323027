export const leftPanelConfig = [
    {
        prop: 'dashboard',
        name: 'Dashboard',
        url : '/dashboard/6795cecc-39d2-4c35-87ac-215c105b0ec1/513294a0-3e20-41b2-a970-6d30bf1546fa', 
        submenu: true,
        active: false,
        highlight: false,
        user: ['HM','LM','BM'],
        userSpecific: true,
        submenuitems: [
            // {
            //     prop: 'dashboard1',
            //     name: 'Titan Expense Analysis',
            //     url : '/dashboard/6795cecc-39d2-4c35-87ac-215c105b0ec1/513294a0-3e20-41b2-a970-6d30bf1546fa', 
            //     submenu: false,
            //     subActive: false,
            //     highlight: true,
            //     type: 'external',
            //     navAccess: []
            // },
            {
                prop: 'dashboard2',
                name: 'Titan Jewellery',
                url : '/dashboard/0b51f17e-4de9-43cc-aa6f-7805eb4b03d0/513294a0-3e20-41b2-a970-6d30bf1546fa', 
                submenu: false,
                subActive: false,
                highlight: true,
                type: 'external',
                navAccess: []
            },
            {
                prop: 'dashboard3',
                name: 'Titan Expense Analysis',
                url : '/dashboard/b766e311-bdea-4a03-9db7-6482e0a9128a/513294a0-3e20-41b2-a970-6d30bf1546fa', 
                submenu: false,
                subActive: false,
                highlight: true,
                type: 'external',
                navAccess: []
            },
        ],
        navAccess: ['DASHBOARD_1', 'DASHBOARD_2', 'DASHBOARD_3', 'DASHBOARD_4']
    },
    {
        prop: 'org',
        name: 'Organization',
        user: ['HM','LM'],
        userSpecific: true,
        url : '/landing/organization-view', 
        submenu: false,
        submenuitems: [],
        navAccess: [],
        highlight: false
    },
    {
        prop: 'task',
        name: 'Task',
        user: ['HM','BM'],
        userSpecific: true,
        url : '/landing/task-view', 
        submenu: false,
        submenuitems: [],
        navAccess: [],
        highlight: false
    },
    {
        prop: 'rules',
        name: 'Rules',
        user: ['HM','BM','LM'],
        userSpecific: true,
        url : '/rule/list-view', 
        submenu: false,
        submenuitems: [],
        navAccess: ['MODIFY_RULE', 'INACTIVE_RULE', 'EMBED_RULE', 'CREATE_RULE', 'SCHEDULE_ML_RUN'],
        highlight: false,
    },
    {
        prop: 'admin',
        name: 'Admin',
        url : '/admin/job-status', 
        submenu: true,
        active: false,
        highlight: false,
        user: ['HM','LM','BM'],
        userSpecific: true,
        submenuitems: [
            {
                prop: 'jobstatus',
                name: 'Job Status',
                url : '/admin/job-status', 
                submenu: false,
                subActive: false,
                highlight: true,
                pageName: 'job-status',
                navAccess: ['CHECK_JOB_STATUS']
            },
            {
                prop: 'ingestion',
                name: 'Data Ingestion',
                url : '/admin/data-ingestion', 
                submenu: false,
                subActive: false,
                highlight: false,
                pageName: 'data-ingestion',
                navAccess: []
            },
            // {
            //     prop: 'userManagement',
            //     name: 'User Management',
            //     url : '/admin/users-view', 
            //     submenu: false,
            //     subActive: false,
            //     highlight: true,
            //     pageName: 'users-view',
            //     navAccess: []
            // },
            // {
            //     prop: 'roleManagement',
            //     name: 'Role Management',
            //     url : '/admin/roles-view', 
            //     submenu: false,
            //     subActive: false,
            //     highlight: false,
            //     pageName: 'roles-view',
            //     navAccess: ['VIEW_ROLES']
            // },
        ],
        navAccess: ['CHECK_JOB_STATUS', 'CREATE_DELETE_USERS', 'MODIFY_USER_ROLE_MAPPING', 'VIEW_ROLES']
    },
]