<div class="vim-left-panel-container">
  <!-- <div *ngIf="environmentName != 'PROD'" class="vim-instance-name">{{environmentName}}</div> -->
  <span (click)="redirection('/landing/organization-view')"><img src="../../../assets/pwc-logo.svg"></span>
  <div class="vim-left-panel-navigation-container">
    <ng-container *ngFor="let panelName of leftPanelConfig;let i = index">
      <div *ngIf="!panelName.hidden">
        <div class="vim-navigation-item" (click)="onClickMainOptionDropdown(i)" *ngIf="panelName.user.includes(viewTypeOnUser)">
          <span>{{panelName.name}}</span>
          <span *ngIf="panelName.submenu" class="ml-3"><i class="bi bi-chevron-down"></i></span>
        </div>
  
        <div *ngIf="panelName.submenu && panelName.active">
          <ng-container *ngFor="let sub of panelName.submenuitems; let subIndex = index">
            <div *ngIf="!sub.hidden">
              <div [ngClass]="{'vim-roles-item-selected': sub.highlight}" class="vim-navigation-item"
                (click)="onClickSubOptionDropdown(sub, panelName.submenuitems, subIndex)">
                {{sub.name}}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>