<div [ngClass]="preAuthHeader ? 'vim-header-container-login' : 'vim-header-container'">
    <div class="vim-header-back">
        <span (click)="clickBackButton()" *ngIf="pageConfig?.back">
            <!-- <i class="bi bi-caret-left-fill"></i> -->
            <i class="pi pi-arrow-left" style="font-size: 1.5rem"></i>
        </span>
    </div>
    <span class="lineUp">Anomaly Detection Platform</span>
    <span *ngIf="pageConfig?.dispName" class="vim-page-name lineUp">{{pageConfig?.dispName}}</span>
    <!-- <span class="lineUp" *ngIf="headerService.pageDetails">{{headerService.pageDetails}}</span> -->
    <span class="vim-header-user-avatar" *ngIf="!preAuthHeader">
        <span *ngIf="!(spinnerService.onLoadingChanged | async)"><span class="vim-header-refresh"
                *ngIf="pageConfig?.refresh" (click)="reloadCurrentPage()">
                <!-- <i class="bi bi-arrow-clockwise"></i> -->
                <i class="pi pi-sync" style="font-size: 1.5rem"></i>
            </span>
        </span>
                
        <p-avatar shape="circle" (click)="userActionPanel.toggle($event)" label="{{userInitial}}"></p-avatar>
    </span>
</div>

<!-- User Avatar Popup -->
<p-overlayPanel #userActionPanel>
    <ng-template pTemplate>
        <div class="vim-user-details">
            <p-avatar shape="circle" label="{{userInitial}}"></p-avatar>
            {{authService.userDetails.userName}}
        </div>
        <div class="vim-user-details">
            Role: <b>{{getRoleName(authService.userDetails.userRole)}}</b>
        </div>
        <!-- <div class="vim-user-details">
            Last Login Time: {{this.userlastLoginTime}}
        </div> -->
        <div class="vim-user-action-container">
            <button class="btn vim-button" (click)="userLogout();">Logout</button>
        </div>
    </ng-template>
</p-overlayPanel>