import { Injectable } from '@angular/core';
import { urlConfig } from './apiUrlConfig';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiUrlService {
  private apiUrl: any;
  private apiPrefix: string;
  private link: string = '';
  constructor(protected configService: ConfigService) {
    this.link = this.configService.getConfigData('url');
    this.apiPrefix = `${this.link}/api`;
    this.populateApiUrl();
  }

  private populateApiUrl(): void {
    this.apiUrl = urlConfig;
  }
  public getUrl(key: string): string {
    // let urlObj = this.apiUrl.filter(value => {
    //   return key === value
    // });
    return `${this.apiPrefix}`+this.apiUrl[key]
  }
}


