import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { finalize, Observable, takeUntil } from 'rxjs';
import { HttpCancelService } from './http-cancel.service';
import { ActivationEnd, Router } from '@angular/router';
import { AuthService } from '../modules/auth/service/auth.service';
import { ENV } from '../shared/global.service';
import { SpinnerService } from './spinner.service';
import { BrowserStorageService } from '../shared/browser-storage.service';

@Injectable()
export class VimInterceptor implements HttpInterceptor {
  appTimeout: any;
  constructor(
    private router: Router,
    private httpCancelService: HttpCancelService,
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private browserStorageService: BrowserStorageService) {
    this.checkRouteChangeEvents();
  }

  checkRouteChangeEvents() {
    this.router.events.subscribe(event => {
      // An event triggered at the end of the activation part of the Resolve phase of routing.
      if (event instanceof ActivationEnd) {
        // Cancel pending calls
        this.httpCancelService.cancelPendingRequests();
      }
    });
  }

  startlogoutTimeout() {
    let appState = this;
    clearTimeout(this.appTimeout);
    this.appTimeout = setTimeout(function () {
      appState.authService.userLogout();
    }, ENV.app.logoutTime);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.startlogoutTimeout();
    request = this.addUserDetailsHeader(request);
    this.spinnerService.onStarted(request);
    return next.handle(request).pipe(
      finalize(() => {
        this.spinnerService.onFinished(request)
        takeUntil(
          this.httpCancelService.onCancelPendingRequests()
        )
      })
    )
  }

  addUserDetailsHeader(request){
    if(this.authService.userDetails.uid){
      request = request.clone({headers: request.headers.append('uid',this.authService.userDetails.uid )});
    } else { // forgot/reset password login with default password
      let defaultLoginUser = JSON.parse(this.browserStorageService.getValueInSession('default-login-user'));
      if(defaultLoginUser?.uid) {
        request = request.clone({headers: request.headers.append('uid', defaultLoginUser?.uid )});
      }
    }
    return request;
  }
}