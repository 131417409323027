<p-toast [baseZIndex]="50000"></p-toast>
<div class="vim-app-body">
  <div class="row vim-app-body-row">
    <div class="col-1 vim-app-left-panel-container" *ngIf="!hideLeftPanel">
      
      <app-left-panel ></app-left-panel>
    </div>
    <div class=" vim-app-router-container" [ngClass]="hideLeftPanel ? 'col-12' : 'col-11'">
      <app-header></app-header>
      <div class="vim-app-body-container">
        <router-outlet></router-outlet>
        <div class="vim-app-body-bottom-spacer"></div>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>
<ng-container *ngIf="spinnerService.onLoadingChanged | async">
  <app-spinner></app-spinner>
</ng-container>
