import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class MessagingService {
    private subject = new Subject<any>();
    public reloadData = new BehaviorSubject({refresh: false, pageConfig: {name: ''} }); 
    constructor(){
        this.reloadData.observers = [];
    }

    sendMessage(message: any) {
        if(message)
        this.subject.next(message);
    }

    onMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    sendReloadData(message: any) {
        if(message)
        this.reloadData.next(message);
    }

    onReloadData(): Observable<any> {
        return this.reloadData.asObservable();
    }

    destroySubscription(){
        if(this.reloadData)
        this.reloadData.unsubscribe();

    }

    ngOnDestroy(){

    }
}