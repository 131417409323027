import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserAccess } from '../modules/auth/models/auth.model';
import { AuthService } from '../modules/auth/service/auth.service';
import { BrowserStorageService } from '../shared/browser-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RulesGuard implements CanActivate {
  constructor(private router: Router, private browserStorageService: BrowserStorageService, private authService: AuthService){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let userAccess = UserAccess;
      let access = false;
      let navAccess = ['MODIFY_RULE', 'INACTIVE_RULE', 'EMBED_RULE', 'CREATE_RULE', 'SCHEDULE_ML_RUN'];
      if(this.browserStorageService.getValueInSession('userAccess')) {
        let userRole: UserAccess = JSON.parse(this.browserStorageService.getValueInSession('userAccess'));
        for(let accessItem of navAccess) {
          if(userRole[userAccess[accessItem]]) {
            access = true;
            break;
          }
        }
      }

      if(access) {
        return true;
      } else {
        this.router.navigate(['/auth/not-authorized']);
        return false;
      }
  }
  
}
