import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from "primeng/dropdown";
import { MultiSelectModule } from 'primeng/multiselect';
import { SharedModule } from 'primeng/api';
import { ToggleButtonModule } from "primeng/togglebutton";
import { SelectButtonModule } from "primeng/selectbutton";
import { ButtonModule } from "primeng/button";
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { FileUploadModule } from 'primeng/fileupload';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import { AvatarModule } from 'primeng/avatar';
import { InputTextModule } from 'primeng/inputtext';
import {TabViewModule} from 'primeng/tabview';
import {CardModule} from 'primeng/card';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {CheckboxModule} from 'primeng/checkbox';
import {TimelineModule} from 'primeng/timeline';
import {DialogModule} from 'primeng/dialog';
import {ChipsModule} from 'primeng/chips';
import { ScrollPanelModule} from 'primeng/scrollpanel';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DropdownModule,
    MultiSelectModule,
    SharedModule,
    PaginatorModule,
    TableModule,
    ToastModule,
    CalendarModule,
    OverlayPanelModule,
    ToggleButtonModule,
    SelectButtonModule,
    ButtonModule,
    PaginatorModule,
    TableModule,
    OverlayPanelModule,
    CalendarModule,
    MessageModule,
    MessagesModule,
    FileUploadModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
    TooltipModule,
    AvatarModule,
    InputTextModule,
    TabViewModule,
    CardModule,
    InputTextareaModule,
    CheckboxModule,
    TimelineModule,
    DialogModule,
    ChipsModule,
    ScrollPanelModule
  ],
  exports: [
    DropdownModule,
    MultiSelectModule,
    SharedModule,
    PaginatorModule,
    TableModule,
    ToastModule,
    CalendarModule,
    OverlayPanelModule,
    ToggleButtonModule,
    SelectButtonModule,
    ButtonModule,
    PaginatorModule,
    TableModule,
    OverlayPanelModule,
    CalendarModule,
    MessageModule,
    MessagesModule,
    FileUploadModule,
    SplitButtonModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
    TooltipModule,
    AvatarModule,
    InputTextModule,
    TabViewModule,
    CardModule,
    InputTextareaModule,
    CheckboxModule,
    TimelineModule,
    DialogModule,
    ChipsModule,
    ScrollPanelModule
  ]
})
export class PrimengModule { }
