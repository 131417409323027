import { Injectable } from '@angular/core';
import { ENV } from '../shared/global.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private urlPrefix: string;
  private link: string;
  private pageSize: number;
  private configData: any;
  constructor() {
    this.configData = {};
    this.pageSize = 50;
    this.urlPrefix = ENV.urlPrefix;
    this.link = ENV.serverURL + ':' + ENV.port;//'localhost:59167'
    this.populateUrl();
  }
  private populateUrl(): void {
    this.configData = {
      url: `${this.urlPrefix}://${this.link}`,
      pagesize: this.pageSize,
    };
  }
  public getConfigData(key: string): string {
    return this.configData[key]
  }
}
