import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ENV } from '../global.service';
import downloadCsv from 'download-csv';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(
    private route: ActivatedRoute
  ) { 
    this.disableConsoleLogging();
  }

  disableConsoleLogging(){
    if(ENV.debug === false) {
      console.log = function(){}
      console.warn = function(){}
      console.info = function(){}
    }
  }

  checkFileValidation(file, fileTypes) {
    let filename = file.name.split('.');
    if (filename.length > 2) {
      return false;
    } else if(!this.validateFileType(filename[1].toLowerCase(), fileTypes)){
      return false;
    }
    else {
      var format = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/|/?]*$/;
      if (!filename[0].match(format)) {
        return true;
      } else {
        return false;
      }
    }
  }

  validateFileType(type, fileTypes){
    // let fileTypes = ['jpg', 'JPG', 'pdf', 'txt', 'png', 'PNG'];
     if(fileTypes.includes(type)){
       return true;
     } else {
       return false;
     }
   }

  exportToCSV(tableData,columnData,fileName){
    if(!tableData)
      return;
    let columns = {};
    columnData.forEach(element => {
      columns[""+element.prop+""] = element.name;
    });
    downloadCsv(tableData,columns,fileName);
  }

  exportToXLS(tableData,fileName){
    if(!tableData)
      return;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tableData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws,'Sheetl' ) ;
    XLSX.writeFile(wb, fileName+ '.xls' ) ;
  }

  getNameInitials(name): string {
    if(!name)
      return '';

      var names = name.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();
      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
  }

  /** Multidimentional Array or Object Deep copy */
  deepCopy(obj: any): any {
    if(obj == null || typeof obj != "object") { return obj; }
    if(obj.constructor !== Object && obj.constructor !== Array) { return obj; }
    if(obj.constructor === Date || obj.constructor === RegExp || obj.constructor === Function
      || obj.constructor === String || obj.constructor === Number || obj.constructor === Boolean ) {
        return new obj.constructor(obj);
    }
    const to = new obj.constructor();
    for(const name in obj) {
      if(typeof to[name] === 'undefined') {
        to[name] = this.deepCopy(obj[name])
      }
    }
    return to;
  }

  downloadFile(fileN, type){
    let url = '';
    if(type === 'INGESTSAMPLE'){
      url = ENV.downloadConfigIngest.url + fileN + ENV.downloadConfigIngest.sasToken;
    } else if(type === 'USERFILE'){
      url = ENV.downloadConfigUserFiles.url + fileN + ENV.downloadConfigUserFiles.sasToken;
    }

      let data = url.split('/');
      let filename = fileN;

      /* download file individually from url */
      // txt, png, jpg, pdf
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        /* Create a new Blob object using the response
        *  data of the onload object.
        */
        const blob = new Blob([xhr.response], { type: 'image/jpeg' });
        console.log(xhr.response);
        const url= window.URL.createObjectURL(blob);
        //window.open(url);
        //const blob = new Blob([xhr.response], { type: '' });
        // console.log(xhr.response)
        const a: any = document.createElement('a');
        a.style = 'display: none';
        document.body.appendChild(a);
        // const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
      };
      xhr.open('GET', url);
      xhr.send();

  }
}
